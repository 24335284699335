import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import CommonLoader from '../../source/CommonLoader';
//import AnnouncementNotificationCommon from '../../source/WebScreen/Notification/AnnouncementNotificationCommon'
//import NotificationCommon from '../../source/WebScreen/Notification/NotificationCommon'
import HealthTools from '../../source/WebScreen/HeathTools/HealthTools';
import PaymentWalletServiceCloud from '../../source/WebScreen/PaymentWallet/PaymentWalletServiceCloud';
import Signup from './CheckRegistration';
import Signin from './SignIn';
import HipaaAuth from '../../source/WebScreen/Hipaa/HipaaAuth'
import Routes from '../../routes';
import { getProperEmailId, getUserDetails } from '../../actions/homeActions';
import { getDecryptIdAPI } from '../../source/ApiCall';
import { any } from 'prop-types';

const CustomAuth = (props: any) => {
    const [toggle, setToggle] = useState(false);
    const [loadCommonComponent, setLoadCommonComponent] = useState(false);
    const [urlPath, setUrlPath] = useState(false);
    const history = useHistory();
    useEffect(() => {
        console.log('props from custom auth', props)
        StateParam();

    }, [])

    const StateParam =()=>{
        let urlValues = window.location.hash ? window.location.hash.split('=') : [];
        console.log("======== urlValues =======", urlValues);
        console.log(urlValues);
        if(urlValues && urlValues.length > 0) {
            if(urlValues[1]){
                setLoadCommonComponent(true);
                getGateWayToken(urlValues[1]);  
            }
        }
    }
    const getGateWayToken = (params: any) => {
        let request = {
            "username": process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
            "password": process.env.REACT_APP_GETWAY_TOKEN_PASS
            // "password": "x1TXVUtXL6PaBWam"
        }
        let netwellOrganization = Buffer.from(`NETWELL;${new Date()}`).toString('base64')
        axios.post(process.env.REACT_APP_BASE_URL + "v1/login", request,
            {headers : {
              'X-Tenant-Id': netwellOrganization,       
            }}).then(data=>{
            let bearer = data['headers'].authorization
            var array = bearer.split("Bearer ")
            console.log('newBearer - - - ', array);
            localStorage.setItem("bearerToken", array[1]);
            let decodeurl = decodeURIComponent(params);
            getDecodeData(decodeurl);
        })
    }
    const getuserData = async (data: any) => {
        try {
            let email : any = await getProperEmailId(data);
            let userData : any = await getUserDetails(email)
            if (userData) {
                let find = userData.data.memberIdCardList[userData.data.memberIdCardList.length - 1]
                localStorage.setItem('SOURCE', find.source)
                localStorage.setItem("sourceid",userData.data.memberIdCardList[0].memberId)
                localStorage.setItem('CLIENT_ID', find.clientId)
                localStorage.setItem('planId', userData.data.memberIdCardList[0].planId)
                localStorage.setItem('userMail', email)
                sessionStorage.setItem('USER_ACTIVE_SESSION', "true");
                if (userData.data.memberIdCardList[0].guardian) {
                    localStorage.setItem('isGuardian', "true")
                    localStorage.setItem('guardianName', `${userData.data.memberIdCardList[0].guardian.prefix || ""} ${userData.data.memberIdCardList[0].guardian.firstName} ${userData.data.memberIdCardList[0].guardian.middleName||""} ${userData.data.memberIdCardList[0].guardian.lastName} ${userData.data.memberIdCardList[0].guardian.suffix || ""}`)
                  }
                  localStorage.setItem('subscriberName', `${userData.data.memberIdCardList[0].prefix || ""} ${userData.data.memberIdCardList[0].firstName || ""} ${userData.data.memberIdCardList[0].middleName || ""} ${userData.data.memberIdCardList[0].lastName || ""} ${userData.data.memberIdCardList[0].suffix || ""}`)
                let contact = userData.data.memberIdCardList[userData.data.memberIdCardList.length - 1].planInfo.find(
                (obj: any)  => obj.idcardField == 'contact number'
                )
                contact ? localStorage.setItem('CONTACT_NUMBER', contact.fieldValue) : localStorage.setItem('CONTACT_NUMBER', 'NA')
                setUrlPath(true)
            }
        } catch (err){
            window.location.href = '/login';
        }
    }

    const getDecodeData = async (data: any) => {
         await getDecryptIdAPI(data).then((resp :any) =>{
            getuserData(resp);
         }).catch(err=>{
            console.log("went wrong:", err)
         })
    }

    const handleToggle = () => {
        setToggle(!toggle);
    }

    if(loadCommonComponent) {
        return(<div>
            { urlPath ? 
                <Switch>
                   <Routes/>
                </Switch>
                :<div><CommonLoader/></div>
            }
            
       </div>)
    }else{
        return (
            <Switch>
                <Route exact path="/HipaaAuthorization" component={HipaaAuth} />
                <Route exact path="/login" component={Signin} />
                <Route exact path="/check-registration" component={Signup} />
                <Redirect exact from="/" to="/login" />
    
            </Switch>
        )
    }
    
    // return (
    //     <Switch>
    //         <Route exact path="/login" component={Signin} />
    //         <Route exact path="/check-registration" component={Signup} />
    //         <Redirect from="/" to="/login" />

    //     </Switch>
    // )

    // if (toggle) {
    //     return (
    //         <Signup handleToggle={handleToggle} />
    //     )
    // }
    // else {
    //     return (
    //         <div><Signin handleToggle={handleToggle} /></div>
    //     )
    // }

}
export default CustomAuth

