import Fab from '@material-ui/core/Fab'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ForumIcon from '@material-ui/icons/Forum'
import { withStyles } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@material-ui/core'
import React, { Component } from 'react'
import { getEMPI, getMyneedsEOS, gethealthcard } from '../../../ApiCall'
import axios from 'axios'
import CommonFooter from './../../CommonFooter'
import DataTable from './../../DataTable'
import Header from './../../Header'
import './../../MyNeeds.css'
import customStyle from '../../programInfo/CSS/stylesheet_UHS'
import styleJson from '../../../styleJSON'
import { Button, FormControl } from 'react-bootstrap'
import CommonDropDwn from '../../../../components/healthqn/CommonScreens/CommonDropDwn'
import '../../ReimbursementRequest/ReimbursementMob.css'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { TextField } from '@material-ui/core'
import { Modal } from 'react-bootstrap'
import ReimbursementModal1 from '../ReimbursementModal.js/ReimbursementModall1'
import ReimbursementModal2 from '../ReimbursementModal.js/ReimbursementModal2/ReimbursementModal2'
import CommonLoader from '../../../CommonLoader'
import Buttons from '@material-ui/core/Button'
import MobileHeader from '../../../MobileScreen/MobileHeader'
import MobCopyright from '../../../MobileScreen/MobCopyright'
import MobileFooter from '../../../MobileScreen/MobileFooter'
import moment from 'moment'
import { Link } from 'react-router-dom'
const CrudButton = withStyles(customStyle.crudBtn)(Fab)
const CrudButtonNetwell = withStyles(customStyle.crudBtnNetwell)(Fab)
const checkBoxStyles = withStyles(theme => ({
  root: {
    '&$label': {
      color: 'red'
    }
  }
}))

const CssTextField = withStyles(theme => ({
  root: {
    '& .MuiInput-root': {
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: '2px solid #533278'
      },

      '&.MuiInput-underline.Mui-focused:after': {
        borderBottom: '2px solid #533278'
      }
    }
  }
}))(TextField)
export default class ReimbursementLeftSide extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      listMember: [],
      loader: false,
      notfoundmsg1: '',
      notfoundmsg2: '',
      notfoundmsg3: '',
      name: [],
      open: false,
      openpicker:false,
      network: this.props.network,
      digitalcarddata: this.props.data,
      contactNumber: this.props.contactNumber,
      prefix: '',
      groupno: '',
      remData: {},
      idCardData: {},
      showModal: false,
      selectMember: '',
      allMembers: [],
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox6: false,
      openPopup: false,
      label: '',
      providerName: '',
      providerAddress: '',
      providerNumber: '',
      providerNPIName: '',
      providerTAXIDName: '',
      CPTCodes: '',
      disabledBottonPopup: true,
      superBill: null,
      proof: null,
      selectedmemberData: null,
      myDate: null,
      AmountCharged: null,
      paidAmount: null,
      sucesspopup: false
    }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  componentDidMount() {
    // this.getEMPID()
    // this.getUsers()
    this.getInitialData()
    this.getAllFamilyMember()
    if (this.props.memberid) {
      this.getSelctedMemberRedeem(this.props.memberid)
    }
  }

  getAllFamilyMember = () => {
    this.setState({ loader: true })
    let obj = {
      "memberIdSource": localStorage.getItem("sourceid"),
      "email": localStorage.getItem("userMail"),
      "type": ""
    }
    let netwellOrganization = Buffer.from(`NETWELL;${new Date()}`).toString('base64')
    axios.post(process.env.REACT_APP_BASE_URL + "v1/csrportal/idcard", obj,
      {
        headers: {
          'X-Tenant-Id': netwellOrganization,
          Authorization: 'Bearer ' + localStorage.getItem('bearerToken')
        }
      }).then((res) => {
        if (res.data.memberIdCardList != null) {
          var memberIdCardList = [];
          console.log("allMembers", res.data.memberIdCardList)
          this.setState({ listMember: res.data.memberIdCardList })
          res.data.memberIdCardList.map((data) => (
            memberIdCardList.push(`${data.prefix || ""} ${data.firstName.trim()} ${data.middleName || ""} ${data.lastName.trim()} ${data.suffix || ""}`)
          ))
          this.setState({ allMembers: memberIdCardList })

          if (this.props.memberid !== null) {
            let memberdetails = res.data.memberIdCardList.filter(res => res.empId == this.props.memberid)[0];
            this.setState({ selectedmemberData: memberdetails, loader: false })
          } else this.setState({ loader: false })
        } else {
          let req = {
            "memberIdSource": localStorage.getItem("sourceid"),
            "email": localStorage.getItem("userMail"),
            "type": "TE"
          }
          axios.post(process.env.REACT_APP_BASE_URL + "v1/csrportal/idcard", req,
            {
              headers: {
                'X-Tenant-Id': netwellOrganization,
                Authorization: 'Bearer ' + localStorage.getItem('bearerToken')
              }
            }).then((res) => {
              var memberIdCardList = [];
              console.log("allMembers", res.data.memberIdCardList)
              this.setState({ listMember: res.data.memberIdCardList })
              res.data.memberIdCardList.map((data) => (
                memberIdCardList.push(`${data.prefix || ""} ${data.firstName.trim()} ${data.middleName || ""} ${data.lastName.trim()} ${data.suffix || ""}`)
              ))
              this.setState({ allMembers: memberIdCardList })

              if (this.props.memberid !== null) {
                let memberdetails = res.data.memberIdCardList.filter(res => res.empId == this.props.memberid)[0];
                this.setState({ selectedmemberData: memberdetails, loader: false })
              } else this.setState({ loader: false })
            })
        }
      })
  }

  getInitialData = () => {
    axios
      .post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/reimbursement', {
        memberId: '7894376',
        memberName: 'RAM',
        providerName: 'TATA',
        providerAddress: 'Mumbai',
        providerPhone: '908493085',
        providerNpiNum: 'KK4353454',
        providerTaxId: '23432432T',
        cptDiagnosisCode: 'CDE3243',
        providerSuperbill: 'tobediscussed_updated',
        paymentProof: 'tobediscussed_updated'
      })
      .then(({ data }) => {
        console.log(data.response)
        // gethealthcard().then((res2)=>{
        //   console.log(res2)
        // })
        let defaultres2 = {
          memberIdCardList: [
            {
              memberId: '1658939041927',
              empId: '444546589',
              memberSSN: '200001837',
              firstName: 'D',
              lastName: 'T',
              email: 'Dt@yopmail.com',
              phone: '8530137407',
              planId: 'WELP5000',
              groupNo: '',
              enrollmentDate: 1660521600000,
              clientId: '1002',
              planInfo: [
                { id: 636, planId: 'WELP5000', idcardField: 'Benefit', fieldValue: 'WellLife+', status: 'Active', type: 'idcard' },
                {
                  id: 637,
                  planId: 'WELP5000',
                  idcardField: 'Member Commitment Portion (ded)',
                  fieldValue: '$5,000.00',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 638,
                  planId: 'WELP5000',
                  idcardField: 'Preventative/Routine',
                  fieldValue: '$1,500.00',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 639, planId: 'WELP5000', idcardField: 'PCP - copay', fieldValue: '$35.00', status: 'Active', type: 'idcard' },
                {
                  id: 640,
                  planId: 'WELP5000',
                  idcardField: 'PCP visits',
                  fieldValue: '9 shared up to $500 max per visit',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 641,
                  planId: 'WELP5000',
                  idcardField: 'Specialist or Urgent Care copay',
                  fieldValue: '$55.00',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 642,
                  planId: 'WELP5000',
                  idcardField: 'Specialist or Urgent Care visits',
                  fieldValue: '9 shared up to $500 max per visit',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 643,
                  planId: 'WELP5000',
                  idcardField: 'Emergency Room',
                  fieldValue: '$500 shared up to $7,500 max',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 644,
                  planId: 'WELP5000',
                  idcardField: 'Hospitalization',
                  fieldValue: '100% after MCP',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 645,
                  planId: 'WELP5000',
                  idcardField: 'In/Outpatient Surgery',
                  fieldValue: '100% after MCP',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 646, planId: 'WELP5000', idcardField: 'Maternity Care', fieldValue: '$7,500.00**', status: 'Active', type: 'idcard' },
                {
                  id: 647,
                  planId: 'WELP5000',
                  idcardField: 'Sharing Year max',
                  fieldValue: '$7,50,000.00',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 648, planId: 'WELP5000', idcardField: 'Lifetime max', fieldValue: '$15,00,000.00', status: 'Active', type: 'idcard' },
                { id: 649, planId: 'WELP5000', idcardField: 'prefix', fieldValue: null, status: 'Active', type: 'idcard' }
              ],
              source: 'Netwell Enroll'
            },
            {
              memberId: '1658987591886',
              empId: '444239858',
              memberSSN: '200001838',
              firstName: 'R',
              lastName: 'B',
              email: null,
              phone: null,
              planId: 'WELP5000',
              groupNo: '',
              enrollmentDate: 1660521600000,
              clientId: '1002',
              planInfo: [
                { id: 636, planId: 'WELP5000', idcardField: 'Benefit', fieldValue: 'WellLife+', status: 'Active', type: 'idcard' },
                {
                  id: 637,
                  planId: 'WELP5000',
                  idcardField: 'Member Commitment Portion (ded)',
                  fieldValue: '$5,000.00',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 638,
                  planId: 'WELP5000',
                  idcardField: 'Preventative/Routine',
                  fieldValue: '$1,500.00',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 639, planId: 'WELP5000', idcardField: 'PCP - copay', fieldValue: '$35.00', status: 'Active', type: 'idcard' },
                {
                  id: 640,
                  planId: 'WELP5000',
                  idcardField: 'PCP visits',
                  fieldValue: '9 shared up to $500 max per visit',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 641,
                  planId: 'WELP5000',
                  idcardField: 'Specialist or Urgent Care copay',
                  fieldValue: '$55.00',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 642,
                  planId: 'WELP5000',
                  idcardField: 'Specialist or Urgent Care visits',
                  fieldValue: '9 shared up to $500 max per visit',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 643,
                  planId: 'WELP5000',
                  idcardField: 'Emergency Room',
                  fieldValue: '$500 shared up to $7,500 max',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 644,
                  planId: 'WELP5000',
                  idcardField: 'Hospitalization',
                  fieldValue: '100% after MCP',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 645,
                  planId: 'WELP5000',
                  idcardField: 'In/Outpatient Surgery',
                  fieldValue: '100% after MCP',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 646, planId: 'WELP5000', idcardField: 'Maternity Care', fieldValue: '$7,500.00**', status: 'Active', type: 'idcard' },
                {
                  id: 647,
                  planId: 'WELP5000',
                  idcardField: 'Sharing Year max',
                  fieldValue: '$7,50,000.00',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 648, planId: 'WELP5000', idcardField: 'Lifetime max', fieldValue: '$15,00,000.00', status: 'Active', type: 'idcard' },
                { id: 649, planId: 'WELP5000', idcardField: 'prefix', fieldValue: null, status: 'Active', type: 'idcard' }
              ],
              source: 'Netwell Enroll'
            },
            {
              memberId: '1658987591935',
              empId: '444582778',
              memberSSN: '200001842',
              firstName: 'D',
              lastName: 'R',
              email: null,
              phone: null,
              planId: 'WELP5000',
              groupNo: '',
              enrollmentDate: 1660521600000,
              clientId: '1002',
              planInfo: [
                { id: 636, planId: 'WELP5000', idcardField: 'Benefit', fieldValue: 'WellLife+', status: 'Active', type: 'idcard' },
                {
                  id: 637,
                  planId: 'WELP5000',
                  idcardField: 'Member Commitment Portion (ded)',
                  fieldValue: '$5,000.00',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 638,
                  planId: 'WELP5000',
                  idcardField: 'Preventative/Routine',
                  fieldValue: '$1,500.00',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 639, planId: 'WELP5000', idcardField: 'PCP - copay', fieldValue: '$35.00', status: 'Active', type: 'idcard' },
                {
                  id: 640,
                  planId: 'WELP5000',
                  idcardField: 'PCP visits',
                  fieldValue: '9 shared up to $500 max per visit',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 641,
                  planId: 'WELP5000',
                  idcardField: 'Specialist or Urgent Care copay',
                  fieldValue: '$55.00',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 642,
                  planId: 'WELP5000',
                  idcardField: 'Specialist or Urgent Care visits',
                  fieldValue: '9 shared up to $500 max per visit',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 643,
                  planId: 'WELP5000',
                  idcardField: 'Emergency Room',
                  fieldValue: '$500 shared up to $7,500 max',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 644,
                  planId: 'WELP5000',
                  idcardField: 'Hospitalization',
                  fieldValue: '100% after MCP',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 645,
                  planId: 'WELP5000',
                  idcardField: 'In/Outpatient Surgery',
                  fieldValue: '100% after MCP',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 646, planId: 'WELP5000', idcardField: 'Maternity Care', fieldValue: '$7,500.00**', status: 'Active', type: 'idcard' },
                {
                  id: 647,
                  planId: 'WELP5000',
                  idcardField: 'Sharing Year max',
                  fieldValue: '$7,50,000.00',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 648, planId: 'WELP5000', idcardField: 'Lifetime max', fieldValue: '$15,00,000.00', status: 'Active', type: 'idcard' },
                { id: 649, planId: 'WELP5000', idcardField: 'prefix', fieldValue: null, status: 'Active', type: 'idcard' }
              ],
              source: 'Netwell Enroll'
            },
            {
              memberId: '1659513591134',
              empId: '444102347',
              memberSSN: '200001877',
              firstName: 's',
              lastName: 'p',
              email: null,
              phone: null,
              planId: 'WELP5000',
              groupNo: '',
              enrollmentDate: 1660521600000,
              clientId: '1002',
              planInfo: [
                { id: 636, planId: 'WELP5000', idcardField: 'Benefit', fieldValue: 'WellLife+', status: 'Active', type: 'idcard' },
                {
                  id: 637,
                  planId: 'WELP5000',
                  idcardField: 'Member Commitment Portion (ded)',
                  fieldValue: '$5,000.00',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 638,
                  planId: 'WELP5000',
                  idcardField: 'Preventative/Routine',
                  fieldValue: '$1,500.00',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 639, planId: 'WELP5000', idcardField: 'PCP - copay', fieldValue: '$35.00', status: 'Active', type: 'idcard' },
                {
                  id: 640,
                  planId: 'WELP5000',
                  idcardField: 'PCP visits',
                  fieldValue: '9 shared up to $500 max per visit',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 641,
                  planId: 'WELP5000',
                  idcardField: 'Specialist or Urgent Care copay',
                  fieldValue: '$55.00',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 642,
                  planId: 'WELP5000',
                  idcardField: 'Specialist or Urgent Care visits',
                  fieldValue: '9 shared up to $500 max per visit',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 643,
                  planId: 'WELP5000',
                  idcardField: 'Emergency Room',
                  fieldValue: '$500 shared up to $7,500 max',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 644,
                  planId: 'WELP5000',
                  idcardField: 'Hospitalization',
                  fieldValue: '100% after MCP',
                  status: 'Active',
                  type: 'idcard'
                },
                {
                  id: 645,
                  planId: 'WELP5000',
                  idcardField: 'In/Outpatient Surgery',
                  fieldValue: '100% after MCP',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 646, planId: 'WELP5000', idcardField: 'Maternity Care', fieldValue: '$7,500.00**', status: 'Active', type: 'idcard' },
                {
                  id: 647,
                  planId: 'WELP5000',
                  idcardField: 'Sharing Year max',
                  fieldValue: '$7,50,000.00',
                  status: 'Active',
                  type: 'idcard'
                },
                { id: 648, planId: 'WELP5000', idcardField: 'Lifetime max', fieldValue: '$15,00,000.00', status: 'Active', type: 'idcard' },
                { id: 649, planId: 'WELP5000', idcardField: 'prefix', fieldValue: null, status: 'Active', type: 'idcard' }
              ],
              source: 'Netwell Enroll'
            }
          ]
        }
        const filteredData = defaultres2.memberIdCardList.map(obj => {})
        this.setState({
          remData: data.response,
          idCardData: defaultres2,
          filteredDropDowndata: filteredData
        })
      })
  }
  myneedsEOS = () => {
    getMyneedsEOS().then(res => {
      if (res.data && res.data.length > 0) {
        this.setState({ tableData: res.data, loader: false })
      } else {
        this.setState({
          notfoundmsg1: 'No share requests have been entered into the system.',
          notfoundmsg2: 'Submitted needs may take 2-3 days to be displayed here.',
          notfoundmsg3: 'For any clarifications call Customer Service.',
          loader: false
        })
      }
    })
  }
  handleDateChange = (date, didMount) => {
    console.log('date---', date)
    this.setState(
      {
        targetDate: date,
        myDate: date
      },
      () => {
        if(date !==null && date.toString()==="Invalid Date"){
          this.setState({openpicker: true, myDate:null})
        }
        let panel = document.getElementById('date-picker-dialog')
        panel.addEventListener('onmouseleave', function() {
          document.getElementById('date-picker-dialog-label').style.paddingTop = '10px'
        })
      }
    )
  }
  showModal = () => {
    this.setState({ show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }
  getSelctedMemberRedeem = empId => {
    axios.get(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/getReimbursementById/' + this.props.referenceId).then(response => {
      if (response.data.code === 200 && response.data.response) {
        this.setState(
          {
            providerName: response.data.response.providerName,
            providerAddress: response.data.response.providerAddress,
            providerNumber: response.data.response.providerPhone,
            providerNPIName: response.data.response.providerNpiNum,
            providerTAXIDName: response.data.response.providerTaxId,
            CPTCodes: response.data.response.cptDiagnosisCode,
            superBill: response.data.response.providerSuperbill,
            proof: response.data.response.paymentProof,
            AmountCharged: response.data.response.chargedAmount,
            paidAmount: response.data.response.paidAmount,
            myDate: response.data.response.serviceDate,
            checkbox1:
              (response.data.response.providerName !== null || response.data.response.providerName !== '') &&
              (response.data.response.providerAddres !== null || response.data.response.providerAddres !== '') &&
              (response.data.response.providerPhone !== null || response.data.response.providerPhone !== '')
                ? true
                : false,
            checkbox2: response.data.response.providerNpiNum ? true : false,
            checkbox3: response.data.response.providerTaxId ? true : false,
            checkbox4: response.data.response.cptDiagnosisCode ? true : false,
            checkbox6: (response.data.response.chargedAmount && response.data.response.paidAmount) ? true : false,
            openPopup: false
          },
          () => {
            if (this.props.memberid !== null) {
              this.setState({ selectMember: response.data.response.memberName })
            }
          }
        )
      } else {
        this.setState({
          providerName: '',
          providerAddress: '',
          providerNumber: '',
          providerNPIName: '',
          providerTAXIDName: '',
          CPTCodes: '',
          superBill: null,
          proof: null,
          AmountCharged: null,
          paidAmount: '',
          myDate: null,
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
          checkbox4: false,
          checkbox6: false,
          openPopup: false
        })
      }
    })
  }
  changeTextFieldHandler = (val, isValid, parentObj) => {
    if (parentObj.label === 'Account_Type') {
      if (isValid) {
        this.state.selectMember = val
      } else {
        this.state.selectMember = ''
      }
    }

    this.setState({ refresh: true }, () => {
      let memberdetails=  this.state.listMember.filter(res=>((`${res.prefix || ""} ${res.firstName.trim()} ${res.middleName || ""} ${res.lastName.trim()} ${res.suffix || ""}`) === (this.state.selectMember.trim())))[0]
      this.setState({ selectedmemberData: memberdetails }, () => {
        // this.getSelctedMemberRedeem(memberdetails.empId)
      })
    })
  }
  render() {
    var disabledButton = true
    if (
      this.state.selectedmemberData &&
      this.state.selectedmemberData.empId &&
      this.state.selectedmemberData.firstName &&
      this.state.providerName &&
      this.state.providerAddress &&
      this.state.providerNumber &&
      this.state.providerNumber.length == 17 &&
      this.state.providerNPIName &&
      this.state.providerTAXIDName &&
      this.state.CPTCodes &&
      this.state.superBill &&
      this.state.proof &&
      this.state.AmountCharged &&
      this.state.paidAmount &&
      this.state.myDate &&
      this.state.checkbox1 &&
      this.state.checkbox2 &&
      this.state.checkbox3 &&
      this.state.checkbox4 &&
      this.state.checkbox6
    ) {
      disabledButton = false
    } else disabledButton = true

    return (
      <>
        <div >
        
          {this.state.loader ? <CommonLoader /> : null}
          <div className="mobileViewHeader">
          <div className="mobile_headerNetWell">
           
              <ArrowBackIcon style={{ width: '24px', height: '24px', color: '#000000' }} onClick={() => {this.props.backClick()}}/>
            
            <div className= "mobile_header_titleNetWell">Reimbursement Request</div>
          </div>
        </div>
          <div style={{ display: 'flex' }}>
            {' '}
           
            <div className="containers progInfoContainer" style={{ zIndex: '0',}}>
            
              <Grid container className="tablebackgroundouter" style={{ marginTop: '45px' }}>
                {' '}
                <Grid lg={6.5} md={8} sm={4}>
                  <div>
                    <Grid lg={4} md={4} sm={4}> 
                      <div style={{ marginTop: '15px',textAlign:"justify" }}>
                        <div className="upperText" style={{ fontSize: '3.5vw', width: '100%' }}>
                     
                          <p>
                            {' '}
                            Reimbursements are requested when a member pays for their visit at time of service, out of their own pocket,
                            which may occur if a Provider is not in the PHCS/Multiplan network or does not recognize a Health Sharing
                            Organization. This form can be used for provider services at either a{' '}
                            <strong>PCP, Specialist, Urgent Care,</strong>
                            or <>Emergency Room</>.
                          </p>

                          <p className="middleText">
                            {' '}
                            Visits <span className="textRed">must</span> be an eligible expense for sharing, per netWell Program Guidelines.
                            If service requires a pre- authorization (See member guide), a pre-authorization request must be initiated by
                            the provider, prior to date of service. Services paid for by member (which are eligible for sharing in
                            accordance with Member Guidelines, will be eligible for reimbursement.
                          </p>

                          <p className="textRed">
                            {' '}
                            Members have 90 days from the date of services to submit complete information for a reimbursement request.
                          </p>

                          <p>
                            {' '}
                            Please refer to the below checklist for information that
                            <span className="textRed"> must</span> be received for processing. Any delay in the information provided, such
                            as inaccurate information, or missing information, member has thirty (30) days, from date of receipt to obtain
                            required documentation for processing. Any information received after that date, will be denied for timely
                            processing.
                          </p>
                        </div>
                      </div>
                    </Grid>

                    <div>
                      <div style={{ minHeight: '535px' }}>
                        <Grid lg={7}>
                          {' '}
                          <CommonDropDwn
                            name={'memberList'}
                            label={'Select Member'}
                            fieldType={'dropDwn'}
                            List={this.state.allMembers}
                            value={this.state.selectMember}
                            disable={this.props.memberid !== null}
                            parentDetails={{ label: 'Account_Type' }}
                            helperMsg={'Coverage required'}
                            setChild={this.changeTextFieldHandler.bind(this)}
                          ></CommonDropDwn>
                          <div className="datePicker" style={{ marginLeft: 0,marginTop:20}}>
                            {' '}
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoComplete="off"
                                margin="none"
                                id={'date-picker-dialog'}
                                label="Date of Service"
                                required ={true}
                                disabled={(this.state.selectMember == null || this.state.selectMember == '')}
                                format="MM/dd/yyyy"
                                value={this.state.myDate}
                                open={this.state.openpicker}
                                onClick={()=>this.setState({openpicker:
                                  (this.state.selectMember == null || this.state.selectMember == '')?false:
                                  true
                                })}
                                onClose={() => this.setState({openpicker:false})}
                                onChange={e => this.handleDateChange(e, false, 'UR_DETAILS', null)}
                                variant="filled"
                                TextFieldComponent={CssTextField}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                                maxDate={new Date()}
                                views={['date']}
                                openTo="year"
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </Grid>
                        <div>
                          <p className="captionText">Click each checklist item to fill in the required information.</p>

                          <Grid item xs={12} md={6} lg={12}>
                            <FormGroup style={{ color: '#4782c4',marginLeft:8 }}>
                              <div>
                                <FormControlLabel
                                  disabled={this.state.selectMember == null || this.state.selectMember == '' || this.state.myDate == null}
                                  control={
                                    <Checkbox
                                      style={{ color: '#533278', padding: '5px 20px 0px 0px', marginRight: 3 }}
                                      checked={
                                        this.state.checkbox1 &&
                                        this.state.providerName &&
                                        this.state.providerAddress &&
                                        this.state.providerNumber && this.state.providerNumber.length == 17
                                      }
                                      onChange={() =>
                                        this.setState(
                                          { checkboxVal: 0, openPopup: true, label: 'Providers Name,  Address,  and Phone Number' },
                                          () => {
                                            if (
                                              this.state.providerName !== '' &&
                                              this.state.providerAddress !== '' &&
                                              this.state.providerNumber !== ''
                                            ) {
                                              this.setState({ disabledBottonPopup: false })
                                            }
                                          }
                                        )
                                      }
                                    />
                                  }
                                  label="Providers Name,  Address,  and Phone Number"
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  disabled={this.state.selectMember == null || this.state.selectMember == '' || this.state.myDate == null}
                                  control={
                                    <Checkbox
                                      style={{ color: '#533278', padding: '5px 20px 0px 0px', marginRight: 3 }}
                                      checked={this.state.checkbox2 && this.state.providerNPIName !== ''}
                                      onChange={() =>
                                        this.setState({ checkboxVal: 1, openPopup: true, label: 'Provider NPI Number' }, () => {
                                          if (this.state.providerNPIName !== '') {
                                            this.setState({ disabledBottonPopup: false })
                                          }
                                        })
                                      }
                                    />
                                  }
                                  label="Provider NPI Number"
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  disabled={this.state.selectMember == null || this.state.selectMember == '' || this.state.myDate == null}
                                  control={
                                    <Checkbox
                                      style={{ color: '#533278', padding: '5px 20px 0px 0px', marginRight: 3 }}
                                      checked={this.state.checkbox3 && this.state.providerTAXIDName !== ''}
                                      onChange={() =>
                                        this.setState({ checkboxVal: 2, openPopup: true, label: 'Provider TAX ID Number' }, () => {
                                          if (this.state.providerTAXIDName !== '') {
                                            this.setState({ disabledBottonPopup: false })
                                          }
                                        })
                                      }
                                    />
                                  }
                                  label="Provider TAX ID Number"
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  disabled={this.state.selectMember == null || this.state.selectMember == '' || this.state.myDate == null}
                                  control={
                                    <Checkbox
                                      style={{ color: '#533278', padding: '5px 20px 0px 0px', marginRight: 3 }}
                                      checked={this.state.checkbox4 && this.state.CPTCodes !== ''}
                                      onChange={() =>
                                        this.setState({ checkboxVal: 3, openPopup: true, label: 'CPT Codes / Diagnosis Codes' }, () => {
                                          if (this.state.CPTCodes !== '') {
                                            this.setState({ disabledBottonPopup: false })
                                          }
                                        })
                                      }
                                    />
                                  }
                                  label="CPT Codes / Diagnosis Codes"
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  disabled={this.state.selectMember == null || this.state.selectMember == '' || this.state.myDate == null}
                                  control={
                                    <Checkbox
                                      style={{ color: '#533278', padding: '5px 20px 0px 0px', marginRight: 3 }}
                                      checked={this.state.checkbox6 && this.state.AmountCharged !== null && this.state.paidAmount !== null}
                                      onChange={() =>
                                        this.setState({ checkboxVal: 6, openPopup: true, label: 'Amount Charged & Paid' }, () => {
                                          if (this.state.AmountCharged !== null && this.state.paidAmount !== null) {
                                            this.setState({ disabledBottonPopup: false })
                                          }
                                        })
                                      }
                                    />
                                  }
                                  label="Amount Charged & Paid"
                                />
                              </div>
                              <div className="labelledText">
                                <FormControlLabel
                                  disabled={this.state.selectMember == null || this.state.selectMember == '' || this.state.myDate == null}
                                  control={
                                    <Checkbox
                                      style={{ color: '#533278', padding: '5px 20px 0px 0px', marginRight: 3 }}
                                      checked={this.state.superBill !== null}
                                      onChange={() =>
                                        this.setState({
                                          checkboxVal: 4,
                                          openPopup: true,
                                          label: `A Provider Issued UB04, 1500 form, also known as a "Super Bill"`
                                        })
                                      }
                                    />
                                  }
                                  label='A Provider Issued UB04, 1500 form, also known as a "Super Bill" '
                                />
                              </div>
                              <Grid item xs={12} md={6} lg={12} style={{ marginLeft: 30,paddingTop:10 }}>
                                <sub style={{ color: 'red', marginTop: '5px', fontSize: '13px' }}>
                                  (Please note- itemized statements, or patient portal screenshots will not be accepted)
                                </sub>
                              </Grid>
                              <div className="labelledText" style={{ marginTop: '20px' }}>
                                <FormControlLabel
                                  disabled={this.state.selectMember == null || this.state.selectMember == '' || this.state.myDate == null}
                                  control={
                                    <Checkbox
                                      checked={this.state.proof !== null}
                                      style={{ color: '#533278', padding: '5px 20px 0px 0px', marginRight: 3 }}
                                      onChange={() =>
                                        this.setState({ checkboxVal: 5, openPopup: true, label: `Proof of Payment for Services` })
                                      }
                                    />
                                  }
                                  label="Proof of Payment for Services"
                                />
                              </div>
                              <Grid item xs={12} md={6} lg={12} style={{ marginLeft: 30,paddingBottom:25 }}>
                                <sub style={{ color: 'red', marginTop: '5px', fontSize: '13px' }}>
                                  (We will only accept a receipt or copy of voided check)
                                </sub>
                              </Grid>
                            </FormGroup>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div className="progInfoFooter" style={{ marginTop: '-5px' }}>
                <div className="row">
                  <div className="col-md-8" style={{ marginTop: '5px' }}>
                    <Button
                      className="S_Button"
                      disabled={this.state.selectMember == null || this.state.selectMember == '' || this.state.myDate == null}
                      onClick={() => this.submitData('PENDING')}
                    >
                      <strong>SAVE</strong>
                    </Button>
                    <Button className="S_Button" disabled={disabledButton} onClick={() => this.submitData('SUBMITTED')}>
                      <strong>SUBMIT</strong>
                    </Button>{' '}
                  </div>

                  <div className="col-md-4 dpFlex">
                    <div className="footerText" style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>
                        <p>Need help?</p>
                        <p>Call our netWell Representative</p>
                        <p>on (866) NETWELL (638-9355).</p>
                      </div>
                      <div style={{ marginLeft: 12 }}></div>
                    </div>
                  </div>
                  <div style={{paddingBottom:28}}>
                  <MobCopyright />
                  </div>
                  <div class="fixed-bottom">
           
          </div>
          <MobileFooter name="Share Requests"/>
                </div>
              </div>
            </div>
          </div>

          <ReimbursementModal1
            title={this.state.label}
            providerName={this.state.providerName}
            setProviderName={value => this.setState({ providerName: value })}
            providerAddress={this.state.providerAddress}
            setProviderAddress={value => this.setState({ providerAddress: value })}
            providerNumber={this.state.providerNumber}
            setproviderNumber={value => this.setState({ providerNumber: value })}
            providerNPIName={this.state.providerNPIName}
            setproviderNPINumber={value => this.setState({ providerNPIName: value })}
            providerTAXIDName={this.state.providerTAXIDName}
            setproviderTAXIDName={value => this.setState({ providerTAXIDName: value })}
            CPTCodes={this.state.CPTCodes}
            setCPTCodes={value => this.setState({ CPTCodes: value })}
            AmountCharged={this.state.AmountCharged}
            setAmountCharged={value => this.setState({ AmountCharged: value })}
            paidAmount={this.state.paidAmount}
            setpaidAmount={value => this.setState({ paidAmount: value })}
            superBill={this.state.superBill}
            setsuperBill={value => this.setState({ superBill: value })}
            proof={this.state.proof}
            setproof={value => this.setState({ proof: value })}
            handleCANCEL={value => this.handleCANCEL(value)}
            openPopup={this.state.openPopup}
            disabledBottonPopup={this.state.disabledBottonPopup}
            setdisabledBottonPopup={value => this.setState({ disabledBottonPopup: value })}
            submitModal={value => this.saveModalData(value)}
            checkbox={this.state.checkboxVal}
          />

          <Modal style={{marginLeft: 10}} dialogClassName="my-modal" show={this.state.sucesspopup} centered className="ChangPayModal" backdrop="static">
            <Modal.Header style={{ height: 56, backgroundColor: '#33afb0', borderBottom: '1px solid #999999' }}>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <h4
                  style={{
                    fontWeight: '500',
                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                    fontSize: '1rem'
                  }}
                >
                  Message
                </h4>
              </Grid>
            </Modal.Header>
            <Modal.Body>
              {this.state.stats == 'SUBMITTED' ? (
                <h5
                  style={{
                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                    fontSize: '16px'
                  }}
                >
                  Your reimbursement request has been submitted successfully.
                </h5>
              ) : (
                <h5
                  style={{
                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                    fontSize: '16px'
                  }}
                >
                  Your reimbursement request has been saved successfully.
                </h5>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Buttons
                className="S_Button"
                style={{
                  backgroundColor: '#4782c4',
                  color: '#ffffff',
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
                }}
                onClick={() => {
                  this.props.backClick()
                  this.setState({ sucesspopup: false })
                }}
              >
                OK
              </Buttons>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    )
  }
  handleCANCEL = value => {
    if (value == 0) {
      if (this.state.checkbox1) this.setState({ checkboxVal: '', openPopup: false, checkbox1: true, label: '', disabledBottonPopup: true })
      else
        this.setState({
          checkboxVal: '',
          openPopup: false,
          checkbox1: false,
          label: '',
          disabledBottonPopup: true,
          providerName: '',
          providerAddress: '',
          providerNumber: ''
        })
    } else if (value == 1) {
      if (this.state.checkbox2) this.setState({ checkboxVal: '', openPopup: false, checkbox2: true, label: '', disabledBottonPopup: true })
      else this.setState({ checkboxVal: '', openPopup: false, checkbox2: false, label: '', disabledBottonPopup: true, providerNPIName: '' })
    } else if (value == 2) {
      if (this.state.checkbox3) this.setState({ checkboxVal: '', openPopup: false, checkbox3: true, label: '', disabledBottonPopup: true })
      else
        this.setState({ checkboxVal: '', openPopup: false, checkbox3: false, label: '', disabledBottonPopup: true, providerTAXIDName: '' })
    } else if (value == 3) {
      if (this.state.checkbox4) this.setState({ checkboxVal: '', openPopup: false, checkbox4: true, label: '', disabledBottonPopup: true })
      else this.setState({ checkboxVal: '', openPopup: false, checkbox4: false, label: '', disabledBottonPopup: true, CPTCodes: '' })
    } else if (value == 4) {
      if (this.state.checkbox5) this.setState({ checkboxVal: '', openPopup: false, checkbox5: true, label: '', disabledBottonPopup: true })
      else this.setState({ checkboxVal: '', openPopup: false, checkbox5: false, label: '', disabledBottonPopup: true })
    } else if (value == 5) {
      if (this.state.checkbox5) this.setState({ checkboxVal: '', openPopup: false, checkbox5: true, label: '', disabledBottonPopup: true })
      else this.setState({ checkboxVal: '', openPopup: false, checkbox5: false, label: '', disabledBottonPopup: true, proof: null })
    } else if (value == 6) {
      if (this.state.checkbox6) this.setState({ checkboxVal: '', openPopup: false, checkbox6: true, label: '', disabledBottonPopup: true })
      else
        this.setState({
          checkboxVal: '',
          openPopup: false,
          checkbox6: false,
          label: '',
          disabledBottonPopup: true,
          AmountCharged: null,
          paidAmount: null
        })
    }
  }
  saveModalData = value => {
    if (value == 0) {
      this.setState({ checkboxVal: '', openPopup: false, checkbox1: true, label: '', disabledBottonPopup: true })
    } else if (value == 1) {
      this.setState({ checkboxVal: '', openPopup: false, checkbox2: true, label: '', disabledBottonPopup: true })
    } else if (value == 2) {
      this.setState({ checkboxVal: '', openPopup: false, checkbox3: true, label: '', disabledBottonPopup: true })
    } else if (value == 3) {
      this.setState({ checkboxVal: '', openPopup: false, checkbox4: true, label: '', disabledBottonPopup: true })
    } else if (value == 4) {
      this.setState({ checkboxVal: '', openPopup: false, checkbox5: true, label: '', disabledBottonPopup: true })
    } else if (value == 5) {
      this.setState({ checkboxVal: '', openPopup: false, checkbox5: true, label: '', disabledBottonPopup: true })
    } else if (value == 6) {
      this.setState({ checkboxVal: '', openPopup: false, checkbox6: true, label: '', disabledBottonPopup: true })
    }
  }
  submitData= (status) => {
    this.setState({loader:true, stats:status})
    const superBillformData = new FormData();
    let dateFormat=moment(this.state.myDate).format("MM-DD-YYYY")
    const UBformData = new FormData();
    let obj= {
      "memberId": this.state.selectedmemberData.empId,
      "memberName": ` ${this.state.selectedmemberData.prefix || ""} ${this.state.selectedmemberData.firstName} ${this.state.selectedmemberData.middleName||""} ${this.state.selectedmemberData.lastName} ${this.state.selectedmemberData.suffix || ""}`,
      "providerName":this.state.providerName,
      "providerAddress":this.state.providerAddress,
      "providerPhone":this.state.providerNumber,
      "providerNpiNum": this.state.providerNPIName,
      "providerTaxId":this.state.providerTAXIDName,
      "cptDiagnosisCode":this.state.CPTCodes,
      // "providerSuperbill":this.state.superBill,
      // "paymentProof":this.state.proof,
      "chargedAmount": this.state.AmountCharged,
      "paidAmount": this.state.paidAmount,
      "primaryEmail": localStorage.getItem("userMail"),
      "serviceDate": this.state.myDate,
      "status": status,
      "id": this.props.referenceId
    }
    if(this.props.memberid){
      
      if(this.state.superBill && this.state.superBill.path){  
          superBillformData.append(
            "file",
            this.state.superBill,
            dateFormat+"_"+this.state.selectedmemberData.empId+"_"+"RECEIPT"+"_"+this.props.referenceId+"."+this.state.superBill.type.split("/")[1].toString()
          );
            axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", superBillformData).then((response) => {
              obj.providerSuperbill=response.data.response;
              if(this.state.proof && this.state.proof.path){
                UBformData.append(
                  "file",
                  this.state.proof,
                  dateFormat+"_"+this.state.selectedmemberData.empId+"_"+"UB04"+"_"+this.props.referenceId+"."+this.state.proof.type.split("/")[1].toString()
                );
                axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", UBformData).then((resp) => {
                    obj.paymentProof = resp.data.response;
                    this.updateAPICallSubmit(obj)
                })
              }else{
                obj.paymentProof = this.state.proof;
                this.updateAPICallSubmit(obj)
              }

            })
          }else if(this.state.proof && this.state.proof.path){
          UBformData.append(
            "file",
            this.state.proof,
            dateFormat+"_"+this.state.selectedmemberData.empId+"_"+"UB04"+"_"+this.props.referenceId+"."+this.state.proof.type.split("/")[1].toString()
          );
          axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", UBformData).then((resp) => {
              obj.paymentProof = resp.data.response;
              if(this.state.superBill && this.state.superBill.path){  
                superBillformData.append(
                  "file",
                  this.state.superBill,
                  dateFormat+"_"+this.state.selectedmemberData.empId+"_"+"RECEIPT"+"_"+this.props.referenceId+"."+this.state.superBill.type.split("/")[1].toString()
                );
                  axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", superBillformData).then((response) => {
                    obj.providerSuperbill=response.data.response;
                    this.updateAPICallSubmit(obj);
                  })
                }else{
                  obj.providerSuperbill=this.state.superBill;
                  this.updateAPICallSubmit(obj);
                }
            
          })
        }else{
          obj.paymentProof = this.state.proof;
          obj.providerSuperbill=this.state.superBill;
          this.updateAPICallSubmit(obj)
        }
        
    }else{
      let GetIdForImgOBJ={
        "status": "PENDING",
        "memberId": this.state.selectedmemberData.empId,
        "memberName": ` ${this.state.selectedmemberData.prefix || ""} ${this.state.selectedmemberData.firstName} ${this.state.selectedmemberData.middleName||""} ${this.state.selectedmemberData.lastName} ${this.state.selectedmemberData.suffix || ""}`,
      }
  
      axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/reimbursement", GetIdForImgOBJ).then((res) => {

        obj.id=res.data.response.id;
        if(this.state.superBill && this.state.superBill.path){
          superBillformData.append(
            "file",
            this.state.superBill,
            dateFormat+"_"+this.state.selectedmemberData.empId+"_"+"RECEIPT"+"_"+res.data.response.id+"."+this.state.superBill.type.split("/")[1].toString()
          );
          axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", superBillformData).then((response) => {
                obj.providerSuperbill= response.data.response;
                if(this.state.proof && this.state.proof.path){
                  UBformData.append(
                    "file",
                    this.state.proof,
                    dateFormat+"_"+this.state.selectedmemberData.empId+"_"+"UB04"+"_"+res.data.response.id+"."+this.state.proof.type.split("/")[1].toString()
                  );
                  axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", UBformData).then((resp) => {
                    obj.paymentProof= resp.data.response;
                    this.updateAPICallSubmit(obj)
                  })
                }else{
                  obj.paymentProof = this.state.proof;
                  this.updateAPICallSubmit(obj)
                }
          })
        }else if(this.state.proof && this.state.proof.path){
          UBformData.append(
            "file",
            this.state.proof,
            dateFormat+"_"+this.state.selectedmemberData.empId+"_"+"UB04"+"_"+res.data.response.id+"."+this.state.proof.type.split("/")[1].toString()
          );
          axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", UBformData).then((resp) => {
            obj.paymentProof= resp.data.response;
            if(this.state.superBill && this.state.superBill.path){
              superBillformData.append(
                "file",
                this.state.superBill,
                dateFormat+"_"+this.state.selectedmemberData.empId+"_"+"RECEIPT"+"_"+res.data.response.id+"."+this.state.superBill.type.split("/")[1].toString()
              );
              axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", superBillformData).then((response) => {
                    obj.providerSuperbill= response.data.response;
                    this.updateAPICallSubmit(obj);})
              }else{
                obj.providerSuperbill=this.state.superBill;
                this.updateAPICallSubmit(obj);
              }
          })
        }else{
          obj.providerSuperbill = this.state.superBill;
          obj.paymentProof = this.state.proof;
          this.updateAPICallSubmit(obj)
        }
        
     
      })

    }
  }
  updateAPICallSubmit=(obj)=>{
    axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/reimbursement", obj).then((response) => {
      this.setState({loader:false, sucesspopup: true})
    })
  }
}

